/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {
	Text,
	useToast,
	IconButton,
	Box,
	HStack,
	VStack,
	Icon,
} from '@chakra-ui/react'
import { MdNotifications } from 'react-icons/md'
import { BsInfoCircle, BsChat } from 'react-icons/bs'
import { useAuthContext } from '../../../context/auth.context'
import { useSharedContext } from '../../../context/shared.context'
import { sharedConst } from '../../../utils/action.constant'
import { useDeepStreamContext } from '../../../context/deepstream.context'

const PushNotificationHandler = ({ handleLogout }) => {
	const toast = useToast({
		duration: 8000,
	})
	const {
		state: { authData },
	} = useAuthContext()
	const { dispatch } = useSharedContext()
	const {
		state: { ds },
	} = useDeepStreamContext()

	const [notify_data, set_notify_data] = useState(null)
	const [push_msg, set_push_msg] = useState(null)

	const triggerToast = ({ icon = MdNotifications, title, description, id }) => {
		if (!description || (description && !toast.isActive(id))) {
			toast({
				render: () => (
					<Box
						borderRadius='md'
						p={1}
						border='1'
						bg={localStorage.getItem('color')}
					>
						<HStack>
							<IconButton
								my={2}
								icon={<Icon as={icon} h={5} w={5} />}
								color={'white'}
								bg='none'
								_hover={{
									bg: 'white',
									color: localStorage.getItem('color'),
								}}
							/>
							<VStack w='300px' alignItems='flex-start'>
								<Text fontWeight='500' fontSize='md' color='white'>
									{title || ''}
								</Text>
								{description ? (
									<Text fontWeight='500' color='white'>
										{description}{' '}
									</Text>
								) : null}
							</VStack>
						</HStack>
					</Box>
				),
				id,
				status: 'error',
				duration: 2000,
				position: 'top-right',
				isClosable: true,
				variant: 'left-accent',
			})
		}
	}

	const displayNotify = data => {
		const { message, channel } = data
		const {
			organization_id,
			account_id,
			workforce_id,
			asset_type_id,
			asset_id,
		} = authData
		const { activity_title, description } = message
		if (`${asset_id}` === channel) {
			const { type } = message
			// Handle response with playing notification
			if (type === 'video_call') {
				console.log(message)
			} else if (type === 'video_call_response') {
				console.log(message)
			} else if (type === 'activity_unread') {
				if (
					message.activity_type_category_id === 16 ||
					message.activity_type_category_id === 27
				) {
					triggerToast({
						icon: BsChat,
						title: 'New Message',
					})
					dispatch({
						type: sharedConst.REFRESH_CHAT_COUNT,
						refreshChatCount: true,
					})
				} else {
					console.log([message])

					triggerToast({
						icon: BsChat,
						title: 'New Message',
						description,
						id: message.activity_id,
					})
				}
				//TODO:   Handle activity add participant push notification
			} else if (type === 'logout') {
				handleLogout()
			} else if (type === 'asset_push') {
				setTimeout(() => {
					dispatch({
						type: sharedConst.REFRESH_BROADCAST_COUNT,
						refreshBroadCast: true,
					})
					triggerToast({
						icon: BsInfoCircle,
						title: activity_title,
						description: description.slice(0, 20),
					})
				}, 5000)
			}
		} else if (
			(`${organization_id}` === channel &&
				message.type === 'organization_push') ||
			(`${account_id}` === channel && message.type === 'account_push') ||
			(`${workforce_id}` === channel && message.type === 'workforce_push') ||
			(`${asset_type_id}` === channel && message.type === 'asset_type_push')
		) {
			setTimeout(() => {
				triggerToast({
					icon: BsInfoCircle,
					title: activity_title,
					description: description.slice(0, 20),
				})
				dispatch({
					type: sharedConst.REFRESH_BROADCAST_COUNT,
					refreshBroadCast: true,
				})
			}, 5000)
		}
	}

	const handleNotification = data => {
		set_notify_data(data)
	}

	useEffect(() => {
		if (!!notify_data) {
			if (JSON.stringify(notify_data.message) !== JSON.stringify(push_msg)) {
				set_push_msg(notify_data.message)
				displayNotify(notify_data)
			}
		}
	}, [notify_data])

	useEffect(() => {
		if (authData) {
			const {
				organization_id,
				account_id,
				workforce_id,
				asset_type_id,
				asset_id,
			} = authData
			// TODO: check for first event instead oflistenning continously

			if (!!ds) {
				console.log({ ds })
				ds.on('error', function (error, event, topic) {
					console.log(error, event, topic)
				})
				ds.event.subscribe(`${organization_id}`, data => {
					if (!!data) {
						handleNotification({
							channel: `${organization_id}`,
							message: data,
							ds: true,
						})
					}
				})
				ds.event.subscribe(`${account_id}`, data => {
					if (!!data) {
						handleNotification({
							channel: `${account_id}`,
							message: data,
							ds: true,
						})
					}
				})
				ds.event.subscribe(`${workforce_id}`, data => {
					if (!!data) {
						handleNotification({
							channel: `${workforce_id}`,
							message: data,
							ds: true,
						})
					}
				})
				ds.event.subscribe(`${asset_type_id}`, data => {
					if (!!data) {
						handleNotification({
							channel: `${asset_type_id}`,
							message: data,
							ds: true,
						})
					}
				})
				ds.event.subscribe(`${asset_id}`, data => {
					if (!!data) {
						handleNotification({
							channel: `${asset_id}`,
							message: data,
							ds: true,
						})
					}
				})
			}
		}

		return () => {
			if (authData) {
				const {
					organization_id,
					account_id,
					workforce_id,
					asset_type_id,
					asset_id,
				} = authData

				if (!!ds) {
					ds.event.unsubscribe(`${organization_id}`, data => {
						if (!!data) {
						}
					})
					ds.event.unsubscribe(`${account_id}`, data => {
						if (!!data) {
						}
					})
					ds.event.unsubscribe(`${workforce_id}`, data => {
						if (!!data) {
						}
					})
					ds.event.unsubscribe(`${asset_type_id}`, data => {
						if (!!data) {
						}
					})
					ds.event.unsubscribe(`${asset_id}`, data => {
						if (!!data) {
						}
					})
				}
			}
		}
	}, [authData, ds])

	return <></>
}

export default PushNotificationHandler
