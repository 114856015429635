/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
	Stack,
	Spinner,
	Text,
	SimpleGrid,
	Box,
	Button,
	Icon,
	Flex,
	Heading,
} from '@chakra-ui/react'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { useLoadOrganizationList } from '../../../hooks/asset.hooks'
import {
	removePreviousAuthDetails,
	setLocalAuthDetails,
} from '../../../utils/common.util'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'
import { useHistory } from 'react-router'
import { GA_CATEGORY_NAVBAR } from './../../../containers/repository/repo.utils'
import { useLanguageContext } from '../../../context/locale.context'
import { useLoadS3Creds } from '../../../hooks/shared.hooks'

export default function OrgList({ isOpen = true }) {
	const { mutate, isLoading } = useLoadOrganizationList()
	const { mutate: mutateS3Creds } = useLoadS3Creds()
	const {
		state: { locale },
	} = useLanguageContext()
	const history = useHistory()
	const [orgnizationList, setOrgnizationList] = useState([])

	useEffect(() => {
		if (!isOpen) return
		mutate(
			{},
			{
				onSuccess: async res => {
					const {
						data: { status, response },
					} = res
					if (status === 200) {
						setOrgnizationList(response)
					}
				},
			}
		)
	}, [isOpen])

	const navigateTo = org => {
		console.log('org', org)
		setLocalAuthDetails(org)
		removePreviousAuthDetails()
		mutateS3Creds({})
		if (!!org.organization_inline_data) {
			let parseData = JSON.parse(org.organization_inline_data)
			localStorage.setItem(
				'color',
				!!parseData.color_1 ? parseData.color_1 : '#4daf89'
			)
			localStorage.setItem(
				'font',
				!!parseData.font_name ? parseData.font_name : 'Roboto'
			)
			localStorage.setItem(
				'imgWidth',
				!!parseData.logo_width_in_px && parseData.logo_width_in_px !== 0
					? `${parseData.logo_width_in_px}px`
					: '60px'
			)
		} else {
			localStorage.setItem('color', '#4daf89')
			localStorage.setItem('font', 'Roboto')
			localStorage.setItem('imgWidth', '60px')
		}
		history.push('/files')
		window.location.reload()
	}

	if (isLoading) {
		return (
			<Stack
				my={3}
				justifyContent='center'
				alignItems='center'
				direction='column'
			>
				<Spinner size='lg' />
				<Text color='brand.800'>{locale['Please, wait...']}</Text>
			</Stack>
		)
	}

	return (
		<SimpleGrid
			spacing={5}
			rounded='lg'
			maxH='500px'
			w='400px'
			bg='white'
			boxShadow='md'
			p={4}
			overflowY='scroll'
			columns={1}
		>
			{(orgnizationList || []).map(org => (
				<Flex
					rounded='lg'
					maxH='500px'
					w='100%'
					boxShadow='md'
					alignItems='center'
					p={3}
					key={org.organization_id}
				>
					<Box
						rounded='md'
						size='sm'
						color='brand.800'
						bg='secondary'
						p={1}
						mr={2}
					>
						<Icon as={HiOutlineOfficeBuilding} h={6} w={6} />
					</Box>
					<Flex flex={1}>
						<Heading fontSize='md' fontWeight='500' textTransform='capitalize'>
							{org.organization_name}
						</Heading>
					</Flex>
					<Button
						color='brand.800'
						size='sm'
						variant='outline'
						fontWeight='400'
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_NAVBAR,
							// 	action: 'Organization List',
							// 	label: locale['Navigate to Organization'],
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Organization List',
							// 	buttonName: 'View',
							// })
							navigateTo(org)
						}}
					>
						{locale['View']}
					</Button>
				</Flex>
			))}
		</SimpleGrid>
	)
}
