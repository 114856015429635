/* eslint-disable react-hooks/exhaustive-deps */
import {
	Avatar,
	Box,
	Button,
	Divider,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spacer,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react'
import { QRCodeSVG } from 'qrcode.react'
import React, { useCallback, useEffect, useState } from 'react'
import { BiCalendarExclamation, BiDownload } from 'react-icons/bi'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import {
	MdPeople,
	MdPowerSettingsNew,
	MdAccountCircle,
	MdChat,
} from 'react-icons/md'
import { AiOutlineBarChart } from 'react-icons/ai'
import { Ri24HoursLine } from 'react-icons/ri'
import { GoGraph } from 'react-icons/go'
import { MdLocationCity } from 'react-icons/md'
import { useHistory } from 'react-router'
import GreneLogo from '../../assets/images/grene-logo.png'

import { useAuthContext } from '../../context/auth.context'
import { useLanguageContext } from '../../context/locale.context'
import { loadAwsFile } from '../../utils/aws.util'
import { useLoadOrgConfigList } from '../../hooks/activity.hooks'
import {
	encryptData,
	getLocalAuthDetails,
	timeAgo,
	timelineDateFormatShort,
} from '../../utils/common.util'
import Broadcast from './brodcast'
import DownloadTemplates from './downloadTemplates'
import PushNotificationHandler from './pushNotificationHandler'
import { useSetPassword, useLoadLanguageList } from '../../hooks/asset.hooks'
import { useSharedContext } from '../../context/shared.context'
import { loginConst, localizationConst } from './../../utils/action.constant'
import { useGetTranslations } from './../../hooks/dashboard.hooks'
import { toast } from 'react-toastify'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../containers/repository/repo.utils'
import { GA_CATEGORY_NAVBAR } from './../../containers/repository/repo.utils'
import Select from '../../components/chakra-react-select'
import './style.css'
import { ORGANIZATION_SAVED_IMAGE_PATH } from '../../utils/constant'
import CountdownTimer from '../countdown-timer'

export default function Navbar({
	handleLogout,
	onVideoPlayListner,
	triggerLogoutPopup,
	sessionTime,
	showSessionTimer,
}) {
	const {
		state: { authData },
	} = useAuthContext()
	const { state } = useSharedContext()
	const {
		state: { locale, selectedLanguage },
		dispatch: languageDispatch,
	} = useLanguageContext()
	const { mutate } = useSetPassword()
	const { mutate: loadOrgConfig } = useLoadOrgConfigList()
	const { mutate: loadLanguageTexts } = useGetTranslations()
	const { mutate: loadLanguagesList } = useLoadLanguageList()
	const user = authData
	const {
		organization_file_templates,
		organization_image_path,
		asset_flag_arp_settings_enabled,
	} = authData
	const history = useHistory()
	const { isOpen, onClose, onOpen } = useDisclosure()

	const [managerDetailsParam, setManagerDetailsParam] = useState({
		manager_id: authData.asset_id,
	})
	const [imageData, setImageData] = useState(null)
	const [orgRedirectionData, setorgRedirectionData] = useState([])
	const [profileImageData, setProfileImageData] = useState(null)
	const [languages, setLanguages] = useState([])

	const openWebApp = () => {
		const authPayload = btoa(
			JSON.stringify({
				accountId: authData.account_id,
				assetId: authData.asset_id,
				assetName: authData.operating_asset_first_name,
				countryCode: `+${authData.asset_phone_country_code}`,
				email: authData.asset_email_id,
				encToken: authData.asset_encryption_token_id,
				key: 'web',
				loggedDeviceOs: 'Chrome',
				mobile: authData.asset_phone_number,
				mode: '101',
				operatingAssetId: authData.operating_asset_id,
				organizationId: authData.organization_id,
				workforceId: authData.workforce_id,
				assetImagePath: authData.asset_image_path,
			})
		)
		const encryptedLoginData = encryptData(authPayload)
		const tonyWebUrl = `${process.env.REACT_APP_OLD_WEB_URL}#/login/${encryptedLoginData}`
		window.open(tonyWebUrl, '_blank')
	}

	const setSelectedLanguage = selectedLanguage => {
		loadLanguageTexts(
			{
				asset_id: authData.asset_id,
				asset_token_auth: authData.asset_token_auth,
				language_id: selectedLanguage.value,
				limit_value: 600,
				start_from: 0,
			},
			{
				onSuccess: data => {
					languageDispatch({
						type: localizationConst.SET_SELECTED_LANGUAGE,
						selectedLanguage,
						data,
					})
				},
				onError: err => {
					toast({
						title: `${err.message}`,
						position: `top-right`,
						isClosable: true,
						status: 'error',
					})
				},
			}
		)
	}

	// useEffect(() => {
	//   if (!!organization_image_path) {
	//     loadAwsFile(organization_image_path, d => {
	//       let data = 'data:' + d.type + ';base64,' + d.data;
	//       setImageData(data);
	//     });
	//   }
	// }, [organization_image_path]);
	useEffect(() => {
		setTimeout(() => {
			if (!!authData.asset_image_path) {
				loadAwsFile(authData.asset_image_path, d => {
					let data = 'data:' + d.type + ';base64,' + d.data
					setProfileImageData(data)
				})
			}
		}, 1000)
	}, [authData.asset_image_path, profileImageData])

	useEffect(() => {
		if (localStorage.getItem(ORGANIZATION_SAVED_IMAGE_PATH)) {
			setImageData(localStorage.getItem(ORGANIZATION_SAVED_IMAGE_PATH))
		} else {
			setTimeout(() => {
				if (!!organization_image_path) {
					loadAwsFile(organization_image_path, d => {
						let data = 'data:' + d.type + ';base64,' + d.data
						setImageData(data)
						localStorage.setItem(ORGANIZATION_SAVED_IMAGE_PATH, data)
					})
				}
			}, 100)
		}
	}, [organization_image_path, imageData])

	const contactDetail = () => {
		const {
			operating_asset_first_name,
			asset_first_name,
			asset_email_id,
			asset_phone_country_code,
			asset_phone_number,
			organization_name,
		} = authData

		if (!!operating_asset_first_name) {
			// return `MECARD:N:${operating_asset_first_name.trim().split(' ').join()};NICKNAME:${asset_first_name};DPT:${workforce_name};TEL:+${asset_phone_country_code}${asset_phone_number};EMAIL:${asset_email_id};;`;
			return `BEGIN:VCARD\nVERSION:3.0\nN:${operating_asset_first_name}\nORG:${organization_name}\nTITLE:${asset_first_name}\nTEL:+${asset_phone_country_code}${asset_phone_number}\nEMAIL:${asset_email_id}\nEND:VCARD`
			// return `MECARD:N:${operating_asset_first_name.trim().split(' ').join()};NICKNAME:${asset_first_name};DPT:${workforce_name};TEL:+${asset_phone_country_code}${asset_phone_number};EMAIL:${asset_email_id};;`;
		}
		return ''
	}

	useEffect(() => {
		if (Object.keys(locale).length === 0)
			setSelectedLanguage({ label: 'English', value: 1 })
	}, [locale])

	useEffect(() => {
		loadOrgConfig(
			{},
			{
				onSuccess: async data => {
					if (
						JSON.parse(data[0]?.organization_enterprise_feature_data)?.feature
							?.org_redirections
					) {
						setorgRedirectionData(
							JSON.parse(data[0].organization_enterprise_feature_data).feature
								.org_redirections
						)
					}
				},
				onError: async err => {
					console.log(err, 'err')
				},
			}
		)

		loadLanguagesList(
			{},
			{
				onSuccess: async data => {
					if (!!data?.length) {
						setLanguages(data)
					}
				},
			}
		)
	}, [])

	return (
		<>
			{!!isOpen ? (
				<DownloadTemplates isOpen={isOpen} onClose={onClose} />
			) : null}

			<Flex
				width={'100%'}
				position='fixed'
				top='0'
				zIndex='10'
				height='60px'
				bg='white'
				alignItems='center'
			>
				<Flex
					flexDir='column'
					justifyContent='center'
					alignItems='flex-start'
					ml={2}
				>
					<HStack>
						<Menu>
							<MenuButton>
								<Avatar
									size='md'
									name={authData?.operating_asset_first_name}
									bgColor={localStorage.getItem('color')}
									color='white'
									src={profileImageData}
								/>
							</MenuButton>
							<MenuList maxWidth={'450px'}>
								<Box px={3}>
									<Box>
										<HStack alignItems='center' my={2}>
											<Box
												padding={2}
												rounded='lg'
												h='100px'
												w='100px'
												bg='white'
												boxShadow='sm'
											>
												<QRCodeSVG
													renderAs='canvas'
													style={{
														height: '100%',
														width: '100%',
													}}
													level='Q'
													value={contactDetail()}
												/>
											</Box>
											<VStack
												justifyContent='flex-start'
												alignItems='flex-start'
												flex={1}
											>
												<Heading
													m={0}
													size='md'
													fontSize='sm'
													fontWeight='500'
													textTransform='capitalize'
													color='black'
												>
													{user.operating_asset_first_name}
												</Heading>
												<Text
													style={{ margin: 0 }}
													fontSize='sm'
													textTransform='capitalize'
												>
													{user.asset_type_name}
												</Text>
												{user.asset_email_id && (
													<Text
														style={{ margin: 0 }}
														fontSize='xs'
														textTransform='capitalize'
													>
														{user.asset_email_id}
													</Text>
												)}
												{user.asset_phone_number && (
													<Text
														style={{ margin: 0 }}
														fontSize='xs'
														textTransform='capitalize'
													>
														+ {user.asset_phone_country_code}{' '}
														{user.asset_phone_number}
													</Text>
												)}
											</VStack>
										</HStack>
									</Box>
									<Divider />
									<VStack alignItems='flex-start' spacing={1} pt={2}>
										{!!user.workforce_name && (
											<Text my={0} fontSize='xs' textTransform='capitalize'>
												<Text my={0} as='span' fontWeight='600' mr={1}>
													{locale['Segment']} :
												</Text>
												{user.workforce_name}
											</Text>
										)}
										{user.asset_created_datetime ? (
											<Text>
												<Text as='span' className='w-500'>
													{locale['Created at']} :{' '}
												</Text>
												{timelineDateFormatShort(user.asset_created_datetime)}
											</Text>
										) : null}
										{user.account_name && (
											<Text my={0} fontSize='xs' textTransform='capitalize'>
												<Text my={0} as='span' fontWeight='600' mr={1}>
													{locale['Circle']} :
												</Text>
												{user.account_name}
											</Text>
										)}
										{!!user.asset_last_seen_datetime ? (
											<Text my={0} fontSize='xs' textTransform='capitalize'>
												<Text as='span' fontWeight='600'>
													{locale['Last Seen']} :{' '}
												</Text>
												{timeAgo(user.asset_last_seen_datetime)}
											</Text>
										) : null}

										{!!user.asset_last_location_address ? (
											<Text my={0} fontSize='xs' textTransform='capitalize'>
												<Text as='span' fontWeight='600'>
													{locale['Last Location']} :
												</Text>{' '}
												{user.asset_last_location_address}
											</Text>
										) : null}
									</VStack>
								</Box>
								<Divider />
								<MenuItem
									icon={<Icon as={MdPeople} w={5} h={5} color='brand.800' />}
									onClick={() => history.push('/team')}
								>
									{locale['Team']}
								</MenuItem>
								{authData?.organization_id === 868 ||
								authData?.organization_flag_enable_leave ? (
									<MenuItem
										icon={
											<Icon
												as={BiCalendarExclamation}
												w={5}
												h={5}
												color='brand.800'
											/>
										}
										onClick={() => history.push('/leave')}
									>
										{locale['Unavailabilty']}
									</MenuItem>
								) : null}
								<MenuItem
									icon={
										<Icon as={MdAccountCircle} w={5} h={5} color='brand.800' />
									}
									onClick={() => {
										setManagerDetailsParam({ manager_id: authData.asset_id })
										history.push('/profile')
									}}
								>
									{locale['Profile']}
								</MenuItem>
								{asset_flag_arp_settings_enabled === 1 && (
									<MenuItem
										icon={
											<Icon as={Ri24HoursLine} w={5} h={5} color='brand.800' />
										}
										onClick={() => history.push('/workhours')}
									>
										{locale['Work Hours']}
									</MenuItem>
								)}
								<MenuItem
									icon={
										<Icon
											as={AiOutlineBarChart}
											w={5}
											h={5}
											color='brand.800'
										/>
									}
									onClick={() => history.push('/arpscores')}
								>
									{locale['ARP Scores']}
								</MenuItem>
								<MenuItem
									icon={<Icon as={MdChat} w={5} h={5} color='brand.800' />}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_NAVBAR,
										// 	action: 'Chat',
										// 	label: locale['Open Chat'],
										// })
										history.push('./chats')
									}}
								>
									{locale['Chat']}
								</MenuItem>
								{user?.asset_type_flag_enable_gamification === 1 && (
									<MenuItem
										icon={<Icon as={GoGraph} w={5} h={5} color='brand.800' />}
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_NAVBAR,
											// 	action: 'GScore',
											// 	label: locale['Open GScore'],
											// })
											history.push('./gscore')
										}}
									>
										{locale['GScore']}
									</MenuItem>
								)}
								<MenuItem
									icon={
										<Icon as={MdLocationCity} w={5} h={5} color='brand.800' />
									}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_NAVBAR,
										// 	action: 'WorkStatus',
										// 	label: locale['Open Work Status'],
										// })
										setManagerDetailsParam({ manager_id: authData.asset_id })
										history.push('./workstatus')
									}}
								>
									{locale['Work Status']}
								</MenuItem>
								<MenuItem
									icon={
										<Icon
											as={HiOutlineOfficeBuilding}
											w={5}
											h={5}
											color='brand.800'
										/>
									}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_NAVBAR,
										// 	action: 'Accounts',
										// 	label: locale['Open Accounts'],
										// })
										history.push('./organizations')
									}}
								>
									{locale['Accounts']}
								</MenuItem>
							</MenuList>
						</Menu>
						<Box>
							<HStack spacing={1}>
								<Heading as='h5' size='md' fontWeight='400'>
									{authData?.operating_asset_first_name || ''}
								</Heading>
								{/* <Text isTruncated size="sm" mb={0}>
                  {`(${authData?.asset_first_name})`}
                </Text> */}
							</HStack>
							<Text isTruncated fontSize='sm' mt={0}>
								{authData?.account_name} - {authData?.workforce_name}
							</Text>
						</Box>
					</HStack>
				</Flex>
				<Spacer />
				<Flex h={'100%'}>
					<HStack spacing={4}>
						{/* <Button
              variant="outline"
              size="sm"
              borderRadius="full"
              boxShadow="md"
              colorScheme={localStorage.getItem('color')}
              onClick={() => history.push('/ganttChart')}
            >
              GanttChart
            </Button> */}
						{/* TODO: Use multiple icons base on notifications */}
						{/* <Button
              variant="outline"
              size="sm"
              borderRadius="full"
              boxShadow="md"
              colorScheme={localStorage.getItem('color')}
              onClick={() => {
                openWebApp();
              }}
            >
              OLD Web
            </Button> */}
						<Select
							isMulti={false}
							id={'language-select'}
							variant='outline'
							options={(languages || []).map(val => {
								return {
									label: val.language_name,
									value: val.language_id,
								}
							})}
							placeholder={locale['Select Language']}
							onChange={setSelectedLanguage}
							borderRadius='md'
							value={selectedLanguage}
							size='md'
						/>
						{orgRedirectionData.length
							? orgRedirectionData?.map(
									(item, i) =>
										item?.flag_enable && (
											<Button
												variant='outline'
												size='sm'
												key={i}
												borderRadius='full'
												boxShadow='md'
												colorScheme={localStorage.getItem('color')}
												isDisabled={!item.flag_enable}
												onClick={() => {
													// sectionDetailedTrack({
													// 	category: GA_CATEGORY_NAVBAR,
													// 	action: 'Org Redirection',
													// })
													// dataLayerTagManager('button_click', {
													// 	viewName: 'Navbar',
													// 	buttonName: item.display_name,
													// })
													window.open(item.display_icon, '_blank')
												}}
											>
												{item.display_name}
											</Button>
										)
							  )
							: null}
						<PushNotificationHandler handleLogout={handleLogout} />
						{!!organization_file_templates &&
						!!organization_file_templates.length ? (
							<IconButton
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_NAVBAR,
									// 	action: 'Download Templates',
									// 	label: 'Open Download Templates',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Navbar',
									// 	buttonName: 'Download Templates',
									// })
									onOpen()
								}}
								my={2}
								icon={<Icon as={BiDownload} h={6} w={6} />}
								color={localStorage.getItem('color')}
								_focus={{
									color: localStorage.getItem('color'),
								}}
								_hover={{
									color: 'white',
									bg: localStorage.getItem('color'),
								}}
								_active={{
									color: 'white',
									bg: localStorage.getItem('color'),
								}}
							/>
						) : null}
						<Broadcast />
						{!!imageData ? (
							<Image
								boxSize={localStorage.getItem('imgWidth')}
								objectFit='contain'
								alt='company logo'
								fallbackSrc={GreneLogo}
								src={imageData}
								height={'100px'}
							/>
						) : (
							<Image
								boxSize='50px'
								objectFit='contain'
								alt='company logo'
								fallbackSrc={GreneLogo}
								src={
									'https://stagingweb.greneos.com/static/media/logo.336da18f.png'
								}
							/>
						)}
						{!process.env.REACT_APP_AD_USER_POOL_ID ? (
							<Box
								boxShadow='md'
								width='70px'
								position='relative'
								border={!!showSessionTimer ? '1px solid red' : ''}
								bg='#000'
							>
								<video
									width='100%'
									height='100%'
									autoPlay
									muted
									onPlay={onVideoPlayListner}
									id='userCameraView'
								>
									{' '}
								</video>
								{!!showSessionTimer ? (
									<Box
										className='timeout-popup'
										style={{
											top: '0px',
											position: 'absolute',
											zIndex: 100,
											width: '100%',
											height: '100%',
											display: 'flex',
										}}
									>
										<CountdownTimer
											sessionTime={sessionTime}
											triggerLogoutPopup={triggerLogoutPopup}
										/>
									</Box>
								) : null}
							</Box>
						) : null}

						<Button
							fontWeight='normal'
							border={0}
							borderRadius={0}
							h={'100%'}
							width={'150px'}
							leftIcon={
								<Icon color='brand.900' as={MdPowerSettingsNew} w={8} h={8} />
							}
							textTransform='uppercase'
							color='brand.900'
							variant='outline'
							mr={2}
							_focus={{
								boxShadow: 'none',
							}}
							onClick={e => {
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Navbar',
								// 	buttonName: 'Logout',
								// })
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_NAVBAR,
								// 	action: 'Download Templates',
								// 	label: 'Open Download Templates',
								// })
								handleLogout(e)
							}}
						>
							{locale['LogOut']}
						</Button>
					</HStack>
				</Flex>
			</Flex>
		</>
	)
}
