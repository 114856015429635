import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import OrgList from './OrgList'
import { useLanguageContext } from '../../../context/locale.context'
const Organization = () => {
  const {
    state: { locale },
  } = useLanguageContext()
  return (
    <Box display="flex" justifyContent="center">
      <Box>
      <Text
        textTransform="uppercase"
        fontSize="16px"
        alignSelf="flex-end"
        fontWeight="bold"
      >
          {locale['Accounts']}
      </Text>
     
        <Box>
          <OrgList />
        </Box>
      </Box>
    </Box>
  )
}

export default Organization
